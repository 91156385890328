/**
 * Type: ページ
 * What: 企画展示コーナー
 */
import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../../components/Layout';
import SEO from '../../../components/Seo';
import BreadcrumbComponent from '../../../components/Breadcrumb';
import SimpleSwiper from '../../../components/Slider.fade';
import Image from '../../../util/Image';
import { Button } from '../../../components/Btn';

const pageTitle = '企画展示コーナー';
const pageDescription =
  '野球・ホークスに関する様々な企画展示を開催いたします。';
const pageSlug = 'obm';
const pageLogo = 'logo-obm-single.png';

const sliderDataobj = [
  {
    filename: 'main-history.jpg',
  },
];

// Data Props Template
const Template = () => (
  <Layout pageSlug={pageSlug} bg="bg-4">
    <SEO title={pageTitle} description={pageDescription} />
    <BreadcrumbComponent
      hierarchy={4}
      bread2url="obm/"
      bread2title="王貞治ベースボールミュージアム Supported by DREAM ORDER・89パーク・スタジオ"
      bread3url="obm/museum/"
      bread3title="王貞治ヒストリーゾーン"
      bread4title={pageTitle}
    />
    <section className="pageMain bg-12 bs-4">
      <div className="kv-wrap bg-4">
        <div className="kv-inner">
          <SimpleSwiper data={sliderDataobj} />
        </div>
        <div className="container">
          <div className="inner">
            <div className="pageMain-header">
              <div className="floorNo c-4">
                <span>4</span>
                <span>F</span>
              </div>
              <h3 className="ttl">
                <span className="logo-under ml-0">
                  <span className="ml-0">
                    <b className="is-1">企画展示コーナー</b>
                  </span>
                </span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section sc-museum bg-4" id="specialexhibition">
      <div className="container">
        <div
          className="tac mb30"
          data-sal="slide-up"
          data-sal-easing="ease-out-expo"
        >
          <img
            src="https://e-zofukuoka.com/resource/img/kv_hawks-hashira_240809.jpg"
            alt="ホークスのチームの柱に"
          />
        </div>
        <div className="inner">
          <div className="btn-wrap mb-4">
            <Button
              href="https://e-zofukuoka.com/news/obm/2024080927567/"
              className="btn-primary tac"
            >
              詳細を見る
            </Button>
          </div>
          <div className="btn-wrap">
            <Button href="/obm/museum/">一覧へ戻る</Button>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default Template;
